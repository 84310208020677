<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Godavari Wave Tech</span>
                        <h2>Innovative It Helping Service All Over the World</h2>
                        <p>At Godavari Wave, we specialize in delivering a wide array of tech solutions designed to
                            address the unique challenges faced by businesses in today's fast-paced digital landscape.
                            Our expert team leverages the latest technologies to provide services ranging from software
                            development and IT management to cybersecurity and wireless connectivity.</p>
                        <p>Our approach is centered around understanding each client’s specific needs and tailoring our
                            solutions accordingly. Whether it’s enhancing user experience through visual design,
                            ensuring quality through rigorous QA testing, or streamlining operations with effective IT
                            management, Godavari Wave is dedicated to providing comprehensive support every step of the
                            way.</p>
                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>



<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque
                blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p> -->
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Their innovative approach completely transformed our business operations, making them more
                            efficient and streamlined. We couldn't be more satisfied with the results.</p>
                        <h3>Kabir Khan</h3>
                        <!-- <span>Jain Merchants</span> -->
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>The team delivered an exceptional product, paying close attention to every detail and
                            ensuring a seamless user experience. Their dedication truly exceeded our expectations.</p>
                        <h3>Sharath Rohit</h3>
                        <!-- <span>Company Founder</span> -->
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>We saw a significant improvement in performance and efficiency after their tailored solution
                            was implemented. Their professionalism and expertise were evident throughout the project.
                        </p>
                        <h3>Lokesh </h3>
                        <!-- <span>Company Founder</span> -->
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>From start to finish, they provided reliable, top-notch service and delivered a product that
                            met all our needs. Their commitment to quality truly set them apart.</p>
                        <h3> Ajay Kumar</h3>
                        <!-- <span>Company Founder</span> -->
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>